import React, { ReactElement } from 'react'
import { StyledFacts, StyledFactsItemLabel, StyledFactsItemLI, StyledFactsItemUL, StyledFactsItemValue } from './styled'
import { isArray, isString } from 'lodash'

export const Facts = ({
  label,
  facts,
  maxFactsCount = 3,
}: {
  label: string
  facts: string[] | string
  maxFactsCount?: number
}): ReactElement => {
  // Check if facts exist and is an
  const isFactsArray = facts && isArray(facts)
  // Check if facts exist and is an string
  const isFactsString = facts && isString(facts)

  // Get facts count
  const factsCount = isFactsArray && facts.length
  // Get first facts
  const firstFacts = isFactsArray && facts.slice(0, maxFactsCount)
  // Get rest facts count
  const factsRestCount = isFactsArray && facts.splice(firstFacts.length).length

  return (
    <StyledFacts>
      {label && <StyledFactsItemLabel>{label}</StyledFactsItemLabel>}
      {facts && (
        <StyledFactsItemUL>
          {isFactsArray &&
            (factsCount < maxFactsCount + 1 ? (
              facts.map((fact, i) => (
                <StyledFactsItemLI key={i}>
                  <StyledFactsItemValue>{fact}</StyledFactsItemValue>
                </StyledFactsItemLI>
              ))
            ) : (
              <>
                {facts.map((fact, i) => (
                  <StyledFactsItemLI key={i}>
                    <StyledFactsItemValue>{fact}</StyledFactsItemValue>
                  </StyledFactsItemLI>
                ))}
                <StyledFactsItemLI>
                  <StyledFactsItemValue>+ {factsRestCount}</StyledFactsItemValue>
                </StyledFactsItemLI>
              </>
            ))}
          {isFactsString && (
            <StyledFactsItemLI>
              <StyledFactsItemValue>{facts}</StyledFactsItemValue>
            </StyledFactsItemLI>
          )}
        </StyledFactsItemUL>
      )}
    </StyledFacts>
  )
}
