import * as React from 'react'
import { BlogComponentType } from '../../../types'
import { CustomerStoriesItem } from './CustomerStoriesItem'
import { UseCaseItem } from './UseCaseItem'
import { NewsTeaserItem } from './NewsTeaserItem'
import { ArticleComponentProps } from './types'
import { useI18n } from '../../../lib/context/i18n'
import { componentCategoryMap } from '../../../lib/constants'

export const ArticleTeaserItem = ({ content, isHighlighted, full_slug, translated_slugs }: ArticleComponentProps) => {
  const { component, title, media } = content
  const { getTranslatedUrlFromBlok } = useI18n()

  const to = getTranslatedUrlFromBlok(full_slug, translated_slugs)

  const ArticleComponent = (): JSX.Element => {
    switch (component) {
      case BlogComponentType.CUSTOMER_STORIES: {
        return (
          <CustomerStoriesItem
            title={title}
            category={componentCategoryMap[content.component]}
            to={to}
            isHighlighted={isHighlighted}
            src={content.companyLogo.filename}
            alt={media?.alt}
            industry={content.industry}
            country={content.country}
            numberOfEmployees={content.companySize}
            usedProducts={content.usedProducts.map(product => product.content.productName)}
            description={content.introText}
          />
        )
      }
      case BlogComponentType.USE_CASE: {
        return (
          <UseCaseItem
            title={title}
            category={componentCategoryMap[content.component]}
            to={to}
            component={BlogComponentType.CUSTOMER_STORIES}
            isHighlighted={isHighlighted}
            src={content?.useCaseIcon.filename}
            usedProducts={content.usedProducts}
            alt={content?.useCaseIcon?.alt}
            description={content.introText}
          />
        )
      }
      case BlogComponentType.COMPANY_INSIGHTS:
      case BlogComponentType.PRODUCT_NEWS:
      case BlogComponentType.PDF_KNOWLEDGE:
      default: {
        return (
          <NewsTeaserItem
            title={title}
            category={content.postCategory}
            to={to}
            component={component}
            isHighlighted={isHighlighted}
            src={media?.filename}
            alt={media?.alt}
            description={content.introText}
          />
        )
      }
    }
  }

  return <ArticleComponent />
}
