import React from 'react'
import { ThemeName } from '../../lib/constants'
import { Seo } from '../../components/Seo'
import { DefaultLayout } from '../../layouts/DefaultLayout'
import { useStoryblok } from '../../lib/storyblok'
import { Headline } from '../../components/UI'
import { NewsletterDisturber } from '../../modules/NewsletterDisturber'
import {
  StyledBlogOverviewHeader,
  StyledBlogOverviewHeaderContainer,
  StyledBlogOverviewHighlightedArticlesContainer,
  StyledBlogOverviewPostGrid,
} from './styled'
import { BlogOverviewSubnavigation } from '../../components/Blog/Overview/Subnavigation'
import { BlogOverviewPagination } from '../../components/Blog/Overview/Pagination'
import { BlogOverviewContext } from './types'
import { ArticleTeaserItem } from '../../modules/ArticleTeaser/ArticleItem'

const BlogOverviewTemplate = ({ pageContext }: { pageContext: BlogOverviewContext }) => {
  let { content } = useStoryblok(pageContext)
  const { title, introText, disturber, pageLinks } = content
  const { currentPage, pageCount, prevPage, nextPage, blogPosts, highlightedArticles, paginationLinks } = pageContext

  return (
    <DefaultLayout theme={content?.color_theme ? (`theme-${content.color_theme}` as ThemeName) : undefined}>
      <Seo
        title={pageContext?.field_pageTitle_string || pageContext?.name}
        description={pageContext?.field_pageDescription_string}
        lang={pageContext?.lang}
        url={pageContext?.full_slug}
      />
      <BlogOverviewSubnavigation pageLinks={pageLinks} />
      <StyledBlogOverviewHeader>
        <StyledBlogOverviewHeaderContainer>
          <Headline is="h1">{title}</Headline>
          {introText && (
            <span className="prose">
              <p>{introText}</p>
            </span>
          )}
        </StyledBlogOverviewHeaderContainer>
      </StyledBlogOverviewHeader>
      {highlightedArticles?.length > 0 && currentPage === 0 && (
        <StyledBlogOverviewHighlightedArticlesContainer>
          {highlightedArticles.map(post => (
            <ArticleTeaserItem key={post.uuid} {...post} isHighlighted />
          ))}
        </StyledBlogOverviewHighlightedArticlesContainer>
      )}

      {blogPosts?.length > 0 && (
        <StyledBlogOverviewPostGrid>
          {blogPosts.map(post => {
            return <ArticleTeaserItem key={post.uuid} {...post} />
          })}
        </StyledBlogOverviewPostGrid>
      )}

      <BlogOverviewPagination
        paginationLinks={paginationLinks}
        nextPage={nextPage}
        currentPage={currentPage}
        prevPage={prevPage}
      />
      <NewsletterDisturber blok={disturber.content} />
    </DefaultLayout>
  )
}

export default BlogOverviewTemplate
