import styled, { css } from 'styled-components'
import { typoHeading3, typoHeading5, typoLabel, typoParagraph, typoParagraphS } from '../../../lib/styles/Typography'
import { BlogComponentType } from '../../../types'

import { blogComponentTypeColorMap } from '../../../lib/theme'
import { ArticleAssetComponentProps } from '../types'

export const StyledArticleItemAside = styled.div`
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const StyledArticleItemMain = styled.div`
  flex: 1 1 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledArticleItemCategory = styled.h3<{ component: BlogComponentType; $isHighlighted?: boolean }>`
  ${typoLabel};
  color: ${({ component, theme }) => blogComponentTypeColorMap({ component, theme })};
  margin-bottom: ${({ $isHighlighted }) => (!$isHighlighted ? '0.2rem' : '0rem')};
`

export const StyledArticleItemTitle = styled.h2<ArticleAssetComponentProps>`
  ${({ $isHighlighted }) => (!$isHighlighted ? typoHeading5 : typoHeading3)}
  margin-bottom: 1rem;
`

export const StyledArticleItemDescription = styled.p<ArticleAssetComponentProps>`
  ${({ $isHighlighted }) => (!$isHighlighted ? typoParagraphS : typoParagraph)}
`

export const ArticleWrapperBaseStyle = css<ArticleAssetComponentProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  gap: 1.5rem;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.black[7]};
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.black[1]};
  text-decoration: none;
  transition: box-shadow 0.2s ease-out, background-color 0.3s ease-out;

  &:hover,
  &:focus-visible {
    box-shadow: 0 0 0 4px #000;
  }
`

export const StyledArticleContentSeparator = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    background-color: ${({ theme }) => theme.colors.black[5]};
    height: calc(100%);
    width: 1px;
  }

  @media (max-width: 768px) {
    &::after {
      background-color: ${({ theme }) => theme.colors.black[5]};
      height: 1px;
      width: calc(100%);
    }
  }
`

export const StyledArticleFacts = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
`
