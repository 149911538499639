import { useI18n } from '../../../../lib/context/i18n'
import { Subnavigation, SubnavigationGroup, SubnavigationLink } from '../../../UI'
import { isExternalLink } from '../../../../lib/utils'
import CustomLink from '../../../CustomLink'
import { Dropdown } from '../../../UI/Subnavigation/Dropdown'
import React from 'react'

export const BlogOverviewSubnavigation = ({ pageLinks }) => {
  const { getTranslatedUrlFromLink } = useI18n()
  if (!Array.isArray(pageLinks) || pageLinks.length === 0) return null

  const dropdownLinks = pageLinks?.map(link => ({
    label: link.text,
    to: getTranslatedUrlFromLink(link.link),
  }))

  return (
    <Subnavigation>
      <SubnavigationGroup isDesktop>
        {pageLinks?.map((link, index) => {
          const path = getTranslatedUrlFromLink(link.link)

          return (
            <SubnavigationLink
              icon={isExternalLink(path) ? 'link-external' : undefined}
              as={CustomLink}
              to={path}
              target={isExternalLink(path) ? '_blank' : undefined}
              key={`${link.id}-${index}`}
            >
              {link.text}
            </SubnavigationLink>
          )
        })}
      </SubnavigationGroup>
      {dropdownLinks && (
        <SubnavigationGroup isMobile>
          <Dropdown items={dropdownLinks} />
        </SubnavigationGroup>
      )}
    </Subnavigation>
  )
}
