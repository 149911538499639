import React from 'react'
import { Button } from '../../../UI'
import CustomLink from '../../../CustomLink'
import { StyledPlaceholderButton } from './styled'

const AddButtonIcons = (link, index, currentPage) => (
  <Button
    className={currentPage + 1 === link.page && 'active'}
    $variant="tertiary"
    $size="small"
    as={CustomLink}
    $ignoreTheme
    children={link.page}
    to={link.path}
    key={`${link.path}-${index}`}
  />
)
export const getPaginationLinksToRender = (paginationLinks, currentPage) => {
  // if the current page is within the threshold at the end or the beginning
  const pageThreshold = 4

  if (paginationLinks.length < 6) {
    return paginationLinks.map((link, index) => AddButtonIcons(link, index, currentPage))
  }

  // if the page is in the beginning
  if (currentPage < pageThreshold) {
    return Array().concat(
      paginationLinks.slice(0, pageThreshold + 1).map((link, index) => AddButtonIcons(link, index, currentPage)),
      <StyledPlaceholderButton children="..." key="placehoder-1" />,
      paginationLinks.slice(-1).map((link, index) => AddButtonIcons(link, index, currentPage)),
    )
  }
  // if the current page is in the end
  if (paginationLinks.length - currentPage <= pageThreshold) {
    return Array().concat(
      paginationLinks.slice(0, 1).map((link, index) => AddButtonIcons(link, index, currentPage)),
      <StyledPlaceholderButton key="placehoder-1" children="..." />,
      paginationLinks.slice(-(pageThreshold + 1)).map((link, index) => AddButtonIcons(link, index, currentPage)),
    )
  }

  // if the page is in between
  if (currentPage >= pageThreshold && paginationLinks.length - currentPage > pageThreshold) {
    return Array().concat(
      paginationLinks.slice(0, 1).map((link, index) => AddButtonIcons(link, index, currentPage)),
      <StyledPlaceholderButton key="placehoder-1" children="..." />,
      paginationLinks
        .slice(currentPage - 1, currentPage + 2)
        .map((link, index) => AddButtonIcons(link, index, currentPage)),
      <StyledPlaceholderButton key="placehoder-2" children="..." />,
      paginationLinks.slice(-1).map((link, index) => AddButtonIcons(link, index, currentPage)),
    )
  }
}
