import CustomLink from '../../../CustomLink'
import React from 'react'
import { BlogOverviewPageNavigationContainer, StyledPageNavButtonContainer } from './styled'
import { BlogOverviewPageNavigationProps } from './types'
import { Button } from '../../../UI'
import { getPaginationLinksToRender } from './utils'

export const BlogOverviewPagination = ({
  nextPage,
  currentPage,
  prevPage,
  paginationLinks,
}: BlogOverviewPageNavigationProps) => {
  const paginationLinksToRender = getPaginationLinksToRender(paginationLinks, currentPage)
  return (
    <BlogOverviewPageNavigationContainer>
      {paginationLinks.length > 1 && (
        <>
          <Button
            className={currentPage === 0 ? 'disabled' : undefined}
            $variant="secondary"
            $size="small"
            as={CustomLink}
            $ignoreTheme
            iconLeft="arrow-left"
            title="Previous page"
            to={prevPage}
          />
          <StyledPageNavButtonContainer>{paginationLinksToRender}</StyledPageNavButtonContainer>
          <Button
            $variant="secondary"
            className={currentPage + 1 === paginationLinks.length ? 'disabled' : undefined}
            $size="small"
            as={CustomLink}
            $ignoreTheme
            iconLeft="arrow-right"
            title="Next page"
            to={nextPage}
          />
        </>
      )}
    </BlogOverviewPageNavigationContainer>
  )
}
