import styled from 'styled-components'
import { containerDefault } from '../../lib/styles/Container'
import { getBreakPoint } from '../../lib/styles/breakpoints'

export const StyledBlogOverviewHeader = styled.div`
  ${containerDefault};
  margin-top: 2rem;

  margin-inline: auto;
`
export const StyledBlogOverviewHeaderContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (${getBreakPoint('md')}) {
    width: 80%;
  }
  @media (${getBreakPoint('sm')}) {
    width: auto;
  }
`

export const StyledBlogOverviewPostGrid = styled.div`
  ${containerDefault};
  margin-inline: auto;
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media screen and (max-width: 1200px) {
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 776px) {
    gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  /* @media (${getBreakPoint('md')}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (${getBreakPoint('sm')}) {
    grid-template-columns: 1fr;
    gap: 3rem;
  } */
`
export const StyledBlogOverviewHighlightedArticlesContainer = styled.div`
  ${containerDefault};
  margin-inline: auto;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
