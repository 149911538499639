import styled from 'styled-components'
import { typoLabel } from '../../../lib/styles/Typography'

export const StyledFacts = styled.div``

export const StyledFactsItemLabel = styled.h3`
  ${typoLabel}
  color: ${({ theme }) => theme.colors.black[3]};
  margin-bottom: 0.1rem;
`

export const StyledFactsItemValue = styled.span`
  ${typoLabel}
`

export const StyledFactsItemUL = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;

  & > :not(:last-child):after {
    display: inline-block;
    content: '/';
    margin: 0 0.5rem;
    color: ${({ theme }) => theme.colors.black[4]};
  }
`

export const StyledFactsItemLI = styled.li`
  display: inline-block;
`
