import styled from 'styled-components'
import { containerDefault } from '../../../../lib/styles/Container'
import { StyledButton, StyledButtonText } from '../../../UI/Button/styled'

export const BlogOverviewPageNavigationContainer = styled.div`
  ${containerDefault};
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-inline: auto;
  margin-top: 4rem;
  .disabled {
    &:hover {
      * {
        pointer-events: none;
      }
    }
  }
`

export const StyledPlaceholderButton = styled.span`
  padding: 0.4rem;
`

export const StyledPageNavButtonContainer = styled.div`
  display: flex;

  ${StyledButton}:not(.active) {
    &:hover {
      ${StyledButtonText} {
        color: ${({ theme }) => theme.colors.white[1]};
      }
    }

    ${StyledButtonText} {
      color: ${({ theme }) => theme.colors.black[3]};
    }
  }

  ${StyledButton}.active {
    &:hover {
      ${StyledButtonText} {
        color: ${({ theme }) => theme.colors.black[1]};
      }
    }
    ${StyledButtonText} {
      color: ${({ theme }) => theme.colors.black[1]};
    }
  }
`
