import styled from 'styled-components'
import CustomLink from '../../../../components/CustomLink'
import { ArticleWrapperBaseStyle } from '../styled'
import { ArticleAssetComponentProps } from '../../types'

export const StyledWrapper = styled(CustomLink)<ArticleAssetComponentProps>`
  ${ArticleWrapperBaseStyle};
  padding: ${({ $isHighlighted }) => (!$isHighlighted ? '2.5rem 2rem' : '3rem 2.5rem')};
  border: 1px solid ${({ theme }) => theme.colors.black[1]};
  background-color: ${({ theme }) => theme.colors.white[1]};
  flex-direction: ${({ $isHighlighted }) => $isHighlighted && 'row'};
  gap: ${({ $isHighlighted }) => (!$isHighlighted ? '0' : '3rem')};
  align-items: stretch;

  &:hover,
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.black[7]};
    border-color: transparent;
  }

  @media (max-width: 1200px) {
    padding: 2.5rem 2em;
  }

  @media (max-width: 776px) {
    padding: 2rem 1.5em;
    gap: ${({ $isHighlighted }) => $isHighlighted && '1.5rem'};
    flex-direction: column;
  }
`

export const StyledMediaWrapper = styled.picture<ArticleAssetComponentProps>`
  width: ${({ $isHighlighted }) => (!$isHighlighted ? '6rem' : '6rem')};
  height: ${({ $isHighlighted }) => (!$isHighlighted ? '3.5rem' : '3.5rem')};
  margin-bottom: 1rem;

  &.hide-mobile {
    display: block;
  }

  &.show-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    &.hide-mobile {
      display: none;
    }

    &.show-mobile {
      display: block;
    }
  }
`

export const StyledMedia = styled.img<ArticleAssetComponentProps>`
  height: 100%;
  width: auto;
  object-fit: contain;

  @media (max-width: 768px) {
    margin: ${({ $isHighlighted }) => !$isHighlighted && 'unset'};
  }
`
