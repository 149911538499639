import * as React from 'react'
import {
  StyledArticleContentSeparator,
  StyledArticleFacts,
  StyledArticleItemAside,
  StyledArticleItemCategory,
  StyledArticleItemDescription,
  StyledArticleItemMain,
  StyledArticleItemTitle,
} from '../styled'
import { StyledMedia, StyledMediaWrapper, StyledWrapper } from './styled'
import { Facts } from '../../../../components/UI/Facts'
import { ArticleTeaserCustomerStoriesItem } from '../types'
import { getTruncatedText } from '../../../../lib/utils/getTruncatedText'
import { useTranslation } from '../../../../lib/context/i18n'
import { TranslationKey } from '../../../../lib/context/i18n/interfaces'

export const CustomerStoriesItem = ({
  isHighlighted,
  src,
  alt,
  category,
  to,
  title,
  industry,
  country,
  numberOfEmployees,
  usedProducts,
  component,
  description,
}: ArticleTeaserCustomerStoriesItem) => {
  const descriptionMaxLength = !isHighlighted ? 220 : 300
  const truncatedDescription = getTruncatedText(description, descriptionMaxLength)
  const t = useTranslation()

  return (
    <StyledWrapper $isHighlighted={isHighlighted} to={to}>
      {!isHighlighted ? (
        <>
          {src && (
            <StyledMediaWrapper $isHighlighted={isHighlighted}>
              <StyledMedia $isHighlighted={isHighlighted} src={src} alt={alt} />
            </StyledMediaWrapper>
          )}
          <StyledArticleItemCategory component={component}>{category}</StyledArticleItemCategory>
          <StyledArticleItemTitle $isHighlighted={isHighlighted}>{title}</StyledArticleItemTitle>
          <Facts label={t(TranslationKey.general_usedProducts)} facts={usedProducts} />
        </>
      ) : (
        <>
          <StyledArticleItemMain>
            {src && (
              <StyledMediaWrapper $isHighlighted={isHighlighted} className="show-mobile">
                <StyledMedia $isHighlighted={isHighlighted} src={src} alt={alt} />
              </StyledMediaWrapper>
            )}
            <StyledArticleItemCategory component={component}>{category}</StyledArticleItemCategory>
            <StyledArticleItemTitle $isHighlighted={isHighlighted}>{title}</StyledArticleItemTitle>
            <StyledArticleItemDescription>{truncatedDescription}</StyledArticleItemDescription>
          </StyledArticleItemMain>

          <StyledArticleContentSeparator />

          <StyledArticleItemAside>
            {src && (
              <StyledMediaWrapper $isHighlighted={isHighlighted} className="hide-mobile">
                <StyledMedia $isHighlighted={isHighlighted} src={src} alt={alt} />
              </StyledMediaWrapper>
            )}

            {(industry || country || numberOfEmployees) && (
              <StyledArticleFacts>
                {industry && <Facts label={t(TranslationKey.general_industry)} facts={industry} />}
                {country && <Facts label={t(TranslationKey.general_country)} facts={country} />}
                {numberOfEmployees && <Facts label={t(TranslationKey.general_companySize)} facts={numberOfEmployees} />}
              </StyledArticleFacts>
            )}

            <Facts label={t(TranslationKey.general_usedProducts)} facts={usedProducts} />
          </StyledArticleItemAside>
        </>
      )}
    </StyledWrapper>
  )
}
