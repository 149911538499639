import styled, { css } from 'styled-components'
import CustomLink from '../../../../components/CustomLink'
import {
  ArticleWrapperBaseStyle,
  StyledArticleItemAside,
  StyledArticleItemMain,
  StyledArticleItemTitle,
} from '../styled'
import { ArticleAssetComponentProps } from '../../types'

export const StyledWrapper = styled(CustomLink)<ArticleAssetComponentProps>`
  ${ArticleWrapperBaseStyle};
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
  background-color: ${({ theme }) => theme.colors.black[7]};
  flex-direction: ${({ $isHighlighted }) => $isHighlighted && 'row'};
  gap: 0;
  align-items: stretch;
  
  &:hover,
  &:focus-visible {
    border-color: transparent;
  }

  @media (max-width: 768px) {
    gap: 0;
    flex-direction: column;
    min-width: unset;
  }
}
`

export const StyledMain = styled(StyledArticleItemMain)<ArticleAssetComponentProps>`
  flex-basis: 60%;
  border-radius: 1rem;
  min-height: ${({ $isHighlighted }) => (!$isHighlighted ? 'unset' : '14rem')};
  aspect-ratio: ${({ $isHighlighted }) => (!$isHighlighted ? '16 / 9' : 'unset')};
  position: relative;
  overflow: hidden;

  @media (max-width: 1200px) {
    flex-basis: 50%;
  }

  @media (max-width: 776px) {
    min-height: unset;
    aspect-ratio: 16 / 9;
  }
`

export const StyledAside = styled(StyledArticleItemAside)<ArticleAssetComponentProps>`
  flex-basis: 40%;
  max-width: ${({ hasImage }) => (hasImage ? 'unset' : '34rem')};

  /* ${({ hasImage, $isHighlighted }) =>
    hasImage &&
    css`
      padding: ${!$isHighlighted ? '1rem 1.5rem 1.5rem' : '3rem 2.5rem 2.5rem'};
    `}

  ${({ hasImage, $isHighlighted }) =>
    !hasImage &&
    css`
      padding: ${!$isHighlighted ? '2.5rem 2rem' : '2.5rem'};
    `} */

  padding: ${({ $isHighlighted }) => (!$isHighlighted ? '1rem 1.5rem 1.5rem' : '3rem 2.5rem')};

  @media (max-width: 1200px) {
    flex-basis: 50%;

    /* ${({ hasImage, $isHighlighted }) =>
      hasImage &&
      css`
        padding: ${!$isHighlighted ? '1rem 1.5rem 1.5rem' : '2.5rem 2rem'};
      `}

    ${({ hasImage, $isHighlighted }) =>
      !hasImage &&
      css`
        padding: ${!$isHighlighted ? '2.5rem 2rem' : '2rem'};
      `} */

    padding: ${({ $isHighlighted }) => (!$isHighlighted ? '1rem 1.5rem 1.5rem' : '2.5rem 2rem')};
  }

  @media (max-width: 776px) {
    /* ${({ hasImage, $isHighlighted }) =>
      hasImage &&
      css`
        padding: ${!$isHighlighted ? '1rem 1.5rem 1.5rem' : '1rem 1.5rem 2rem'};
      `}

    ${({ hasImage, $isHighlighted }) =>
      !hasImage &&
      css`
        padding: ${!$isHighlighted ? '2.5rem 2rem' : '1rem 1.5rem 2rem'};
      `} */

    padding: ${({ $isHighlighted }) => (!$isHighlighted ? '1rem 1.5rem 1.5rem' : '1rem 1.5rem 2rem')};
  }
`

export const StyledMediaWrapper = styled.picture<ArticleAssetComponentProps>`
  width: ${({ $isHighlighted }) => (!$isHighlighted ? '6rem' : '6rem')};
  height: ${({ $isHighlighted }) => (!$isHighlighted ? '3.5rem' : '3.5rem')};
  margin-bottom: 1rem;

  &.hide-mobile {
    display: block;
  }

  &.show-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    &.hide-mobile {
      display: none;
    }

    &.show-mobile {
      display: block;
    }
  }
`

export const StyledMedia = styled.img<ArticleAssetComponentProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 768px) {
    margin: ${({ $isHighlighted }) => !$isHighlighted && 'unset'};
  }
`

export const StyledTitle = styled(StyledArticleItemTitle)`
  margin-bottom: ${({ hasImage }) => (hasImage ? '0' : '1rem')};
`

export const StyledYoutubePlayButton = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 3rem;
    height: auto;
  }
`
