import * as React from 'react'
import { useEffect, useState } from 'react'
import { ArticleItemProps } from '../types'
import { StyledArticleItemCategory, StyledArticleItemDescription, StyledArticleItemTitle } from '../styled'
import {
  StyledAside,
  StyledMain,
  StyledMedia,
  StyledMediaWrapper,
  StyledTitle,
  StyledWrapper,
  StyledYoutubePlayButton,
} from './styled'
import PlayButton from '../../../../assets/images/video-play-button.png'
import { extractVidoeIdFromYoutubeUrl } from '../../../../lib/utils/getEmbedUrlFromYouTubeUrl'
import { getTruncatedText } from '../../../../lib/utils/getTruncatedText'

export const NewsTeaserItem = ({
  isHighlighted,
  src,
  alt,
  category,
  to,
  title,
  component,
  description,
}: ArticleItemProps) => {
  const hasImage = !!src
  const [youtubeId, setYoutubeId] = useState<string | null>(null)

  const createImageUrl = url => {
    if (youtubeId) {
      return `https://img.youtube.com/vi/${youtubeId}/sddefault.jpg`
    }
    return url
  }

  useEffect(() => {
    const ytId = extractVidoeIdFromYoutubeUrl(src)
    if (ytId && !youtubeId) {
      setYoutubeId(ytId)
    }
  }, [])
  const descriptionMaxLength = 220
  const truncatedDescription = getTruncatedText(description, descriptionMaxLength)

  return (
    <StyledWrapper $isHighlighted={isHighlighted} to={to}>
      {!isHighlighted ? (
        <>
          {hasImage && (
            <StyledMain $isHighlighted={isHighlighted}>
              <StyledMediaWrapper $isHighlighted={isHighlighted}>
                <StyledMedia $isHighlighted={isHighlighted} src={createImageUrl(src)} alt={alt} />
              </StyledMediaWrapper>
              {youtubeId && (
                <StyledYoutubePlayButton>
                  <img src={PlayButton} alt="Video play button" />
                </StyledYoutubePlayButton>
              )}
            </StyledMain>
          )}
          <StyledAside $isHighlighted={isHighlighted}>
            <StyledArticleItemCategory component={component}>{category}</StyledArticleItemCategory>
            <StyledTitle $isHighlighted={isHighlighted} hasImage={hasImage}>
              {title}
            </StyledTitle>
            {!hasImage && <StyledArticleItemDescription>{truncatedDescription}</StyledArticleItemDescription>}
          </StyledAside>
        </>
      ) : (
        <>
          {hasImage && (
            <StyledMain $isHighlighted={isHighlighted}>
              <StyledMediaWrapper $isHighlighted={isHighlighted}>
                <StyledMedia $isHighlighted={isHighlighted} src={createImageUrl(src)} alt={alt} />
              </StyledMediaWrapper>
              {youtubeId && (
                <StyledYoutubePlayButton>
                  <img src={PlayButton} alt="Video play button" />
                </StyledYoutubePlayButton>
              )}
            </StyledMain>
          )}

          <StyledAside $isHighlighted={isHighlighted} hasImage={hasImage}>
            <StyledArticleItemCategory component={component}>{category}</StyledArticleItemCategory>
            <StyledArticleItemTitle $isHighlighted={isHighlighted}>{title}</StyledArticleItemTitle>
            <StyledArticleItemDescription>{truncatedDescription}</StyledArticleItemDescription>
          </StyledAside>
        </>
      )}
    </StyledWrapper>
  )
}
